<template>
  <div class="terms-of-conditions">
    <div
      :class="[
        'terms-of-conditions__overlay',
        { 'terms-of-conditions__overlay--is-new-user': !isAccepted },
      ]"
    />

    <Modal v-if="showTermsAndConditionModal" @close="handleCloseClick">
      <div
        v-if="termsAndConditions"
        class="terms-of-conditions__modal-content"
        v-html="formatTermsAndConditions()"
      />

      <div v-if="!isAccepted" class="terms-of-conditions__modal-footer">
        <ActionsButton actionColorType="approved" @handleClick="onTermsClick" />
      </div>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/Modal/ModalComponent.vue';

import { TermsAndConditions } from '@/store/v1/store';

import ActionsButton from '@/components/Buttons/ActionsButton.vue';
import ApproveIcon from '@/assets/icons/approve-icon.svg?component';
import DisapproveIcon from '@/assets/icons/disapprove-icon.svg?component';

import { SupportedLanguages } from '@/routes/routesInterface';

export default defineComponent({
  components: {
    Modal,
    ActionsButton,
    ApproveIcon,
    DisapproveIcon,
  },
  data: () => ({
    showTermsAndConditionModal: false,
    closedWithoutAgree: true,
  }),
  props: {
    termsAndConditions: {
      type: Object as PropType<TermsAndConditions>,
      default: null,
    },
    isAccepted: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    termsAndConditions: {
      handler(terms: TermsAndConditions) {
        if (terms) {
          this.showTermsAndConditionModal = true;
        }
      },
      immediate: true,
    },
    showTermsAndConditionModal(bool: boolean) {
      if (this.closedWithoutAgree && !bool) {
        this.showTermsAndConditionModal = true;
      }
    },
  },
  methods: {
    onTermsClick() {
      if (!this.termsAndConditions) {
        return;
      }

      this.$store.dispatch('setTermsAndConditions', { termsId: this.termsAndConditions.terms.id });
      this.closedWithoutAgree = false;
      this.showTermsAndConditionModal = false;
      this.$emit('termsAgreed');
    },
    handleCloseClick() {
      if (this.isAccepted) {
        this.$emit('closeClick');
      }
    },
    formatTermsAndConditions() {
      if (!this.termsAndConditions) {
        return '';
      }
      // TODO: Check why this currentLanguage is en instead of en-GB...
      let currentLanguage: string = this.$store.getters.currentLanguage;
      if (!this.termsAndConditions.terms[currentLanguage]) {
        currentLanguage = 'en';
      }
      return this.termsAndConditions.terms[currentLanguage];
    },
  },
});
</script>

<style lang="scss">
.terms-of-conditions {
  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;

    &--is-new-user {
      background-image: url('~@/assets/images/preview-blur-bg.png');
    }
  }

  &__modal-content {
    overflow: auto;

    .docx {
      width: 100%;
      padding: var(--spacing-xs);
    }
  }
  &__modal-footer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: var(--spacing-m);
  }
}
</style>
