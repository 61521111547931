import { App } from "vue"

import Card from './Cards/CardComponentOld.vue';
import Checkbox from './Inputs/Checkbox.vue';
import ButtonComponent from './Buttons/Normal/ButtonsComponent.vue';
import ModalComponent from '@/components/Modal/ModalComponentOld.vue';

export default {
  install(app: App) {
    // app.component('Card', Card);
    app.component('p-checkbox', Checkbox);
    app.component('p-button', ButtonComponent);
    app.component('p-modal', ModalComponent);
  },
};
