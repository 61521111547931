<template>
  <div class="p-modal-wrapper">
    <transition name="fade">
      <div
        v-if="modelValue"
        class="p-modal-overlay"
        @click="closeModal"
        :class="{ 'p-modal-overlay--absolute': isAbsolute }"
      >
        <div class="p-modal" :style="modalStyle">
          <div class="p-modal-header">
            <span class="p-modal-close-icon" @click="toggleModal">
              <CloseIcon />
            </span>

            <slot name="title"></slot>
          </div>
          <div class="p-modal-body" v-if="hasBody">
            <slot name="body"></slot>
          </div>
          <div class="p-modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    modalStyle: {
      type: [String, Object],
      default: '',
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasBody() {
      return this.$slots.body ? true : false;
    },
  },
  methods: {
    toggleModal() {
      this.$emit('update:modelValue', !this.modelValue);
      if (this.modelValue === false) {
        this.$emit('close', true);
      }
    },
    closeModal(event) {
      const target = event.target;
      const targetHasClass = target.classList.contains('p-modal-overlay');
      if (!this.persistent && targetHasClass) {
        this.$emit('update:modelValue', false);
        this.$emit('close', true);
      }
    },
  },
});
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.p-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(40, 34, 55, 0.06);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &--absolute {
    @media (max-width: 1350px) {
      position: absolute;
    }
  }
}
.p-modal-close-icon {
  float: right;
  cursor: pointer;
  color: black;
}
.p-modal {
  width: 635px;
  min-height: 343px;
  height: auto;
  padding: 1em;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(122, 137, 254, 0.1);
  border-radius: 10px;
  position: relative;
}
.p-modal-header {
  padding-left: 1em;
}

.p-modal-close-icon {
  margin-left: auto;

  stroke: var(--brand-secondary);
}

.p-modal-body {
  margin-top: 2.5em;
  padding-left: 1em;
}
.p-modal-footer {
  position: absolute;
  bottom: 5%;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}
</style>
