import HttpRequest from '@/http/httpClass';
import {
  MENU,
  RESTAURANTS_USER,
  SALES,
  CHANGE_PASSWORD,
  PRICE_COMPARISON,
  AUTH,
  PLEEZ_REPORTS,
  METRICS,
  HOME,
  RESTAURANTS_PLATFORMS,
  CITIES_PLATFORMS,
  MARKET_TRENDS,
  FUNCTIONS,
  INSIGHTS,
  MARKET,
} from '@/http/endpoints';
import { Session } from '@/globals';
import { routerInstance as router } from '@/routes/router';

import { Actions, CardPeriod, MarketInsightsPeriod, PromotionsFilter } from '../store';
import {
  ChangeRestaurantPlatformResponse,
  GetUserRestaurantOrdersResponse,
  GetUserRestaurantsResponse,
  RenewTokenResponse,
  LoginResponse,
  HomeResponse,
  InsightsResponse,
  CommonFiltersResponse,
  CityAndSourcesResponse,
  MarketTrendsResponse,
  PriceComparisonFiltersResponse,
  SuggestedCompetitorsResponse,
  PriceMetricsResponse,
  PriceComparisonResponse,
  InsightType,
  InsightSettings,
  GetUserReportsResponse,
  GetNewRestaurantMetricsOverviewByRestaurantResponse,
  GetNewRestaurantMetricsOverviewByPlatformResponse,
  SavedCompetitorsResponse,
  PriceComparisonMoreFiltersResponse,
} from './actionsResponses';

import dashboardSalesActions from './dashboard/dashboardSalesActions';
import performanceActions from './performance/performanceActions';
import promotionsActions from './promotions/promotionsActions';
import termsAndConditionsActions from './termsAndConditions/termsAndConditionsActions';
import settingsActions from './settings/settingsActions';

import { Platform } from '@/components/Filters/Filters';
import { GetInsightsOptions } from '@/http/endpoints.types';
import { isCSMUser } from '@/utils/roles/';
import { EventInsight } from '@/views/home/events/_eventInsight';

const runtimeCache: { getRestaurantsFromPlatform: Record<string, Promise<unknown>> | null } = {
  getRestaurantsFromPlatform: null,
};

const actions: Actions = {
  ...dashboardSalesActions,
  ...performanceActions,
  ...promotionsActions,
  ...termsAndConditionsActions,
  ...settingsActions,
  login(_, options) {
    const { data, rememberMe } = options;
    const request = new HttpRequest(AUTH, 'POST', false, { data });

    return request.send<LoginResponse>().then((response) => {
      const { accessToken, isSuperUser, idRestaurantSourceMenusdb, roles } = response;

      Session.setSession('pleez-token', accessToken, rememberMe);
      Session.setSession('pleez-persistent', true, rememberMe);
      Session.setSession('isSuperUser', isSuperUser, rememberMe);
      Session.setSession('pleez-roles', roles, rememberMe);
      Session.setSession(
        'pleez-id-restaurant-source-menus-db',
        idRestaurantSourceMenusdb,
        rememberMe,
      );

      return { isSuperUser: isSuperUser };
    });
  },
  renewToken(_, options) {
    const { restaurantId, restaurantSourceId } = options;
    const request = new HttpRequest(`/auth/renewToken/${restaurantSourceId}`, 'POST', true);

    request.send<RenewTokenResponse>().then((response) => {
      const { accessToken, idRestaurantSourceMenusdb } = response;

      if (restaurantId) {
        Session.setSession('pleez-restaurant-id', restaurantId);
      }
      Session.setSession('pleez-token', accessToken, false);
      Session.setSession('pleez-persistent', true, false);
      Session.setSession('pleez-id-restaurant-source-menus-db', idRestaurantSourceMenusdb, false);

      router.go(0);
    });
  },
  downloadFile(_, options) {
    const { downloadLink, title } = options.file;

    fetch(downloadLink, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = title;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox

        a.click();
        a.remove(); //afterwards we remove the element again
      });
  },
  changeRestaurantPlatform({ commit, getters }, options) {
    const newPlatform = options.newPlatform;
    const platforms = getters.restaurantPlatforms;
    const restaurantPlatform = platforms.find(
      (platform: { id: number }) => platform.id === newPlatform,
    );

    if (restaurantPlatform) {
      const request = new HttpRequest(
        `/auth/renewToken/${restaurantPlatform.idRestaurantSourceMenusdb}`,
        'POST',
        true,
      );

      return request.send<ChangeRestaurantPlatformResponse>().then((response) => {
        Session.setSession('pleez-token', response.accessToken, false);
        Session.setSession('pleez-persistent', true, false);
        Session.setSession('pleez-selected-platform', newPlatform, false);
        Session.setSession(
          'pleez-id-restaurant-source-menus-db',
          response.idRestaurantSourceMenusdb,
          false,
        );

        commit('setPlatform', newPlatform);

        router.go(0);
      });
    }
  },
  getRestaurantsFromPlatform({ getters }) {
    const currentPlatform: string = getters.platformById?.name;

    if (!runtimeCache.getRestaurantsFromPlatform?.[currentPlatform]) {
      runtimeCache.getRestaurantsFromPlatform = {
        ...runtimeCache.getRestaurantsFromPlatform,
        [currentPlatform]: new HttpRequest(
          `/restaurants/provider/${currentPlatform}`,
          'GET',
          true,
        ).send(),
      };
    }

    return runtimeCache.getRestaurantsFromPlatform[currentPlatform];
  },
  getRestaurantsLabels({ state }) {
    const currentRestaurant = state.restaurantFilters.restaurants
      .map((restaurant) => restaurant.id)
      .join(',');

    const request = new HttpRequest(
      MENU.CATEGORIES_LABELS({
        idRestaurants: currentRestaurant,
      }),
      'GET',
      true,
    );

    return request.send();
  },
  getUserRestaurants({ commit, getters }) {
    const request = new HttpRequest(RESTAURANTS_USER, 'GET', true);

    return request.send<GetUserRestaurantsResponse>().then((userRestaurants) => {
      const sessionRestaurantId = Number(Session.getSession('pleez-restaurant-id'));
      let selectedPlatform = Number(Session.getSession('pleez-selected-platform'));
      let selectedRestaurant = null;

      commit('setUserRestaurants', userRestaurants);

      if (sessionRestaurantId) {
        selectedRestaurant = userRestaurants.find(
          (restaurant) => restaurant.id === sessionRestaurantId,
        );
      } else {
        selectedRestaurant = userRestaurants.find((restaurant) => {
          return restaurant.restaurants_sources.find((rs) => {
            //FIXME:
            return rs.id === Number(Session.getSession('pleez-id-restaurant-source-menus-db'));
          });
        }) ?? userRestaurants[0];
      }

      if (selectedRestaurant) {
        commit('setSelectedRestaurant', selectedRestaurant);

        const restaurantDefaults = getters.restaurantDefaultPlatforms;
        const restaurantPlatforms = selectedRestaurant.restaurants_sources.map((restaurant) => {
          const defaultRestaurantMatch = restaurantDefaults.find(
            (platform: Platform) => platform.name === restaurant.source.label,
          );

          return {
            idRestaurantSourceMenusdb: restaurant.id,
            name: restaurant.source.label,
            id: restaurant.source.id,
            color: defaultRestaurantMatch ? defaultRestaurantMatch.color : undefined,
          };
        });

        commit('setRestaurantPlatforms', restaurantPlatforms);

        if (!selectedPlatform) {
          selectedPlatform = restaurantPlatforms[0].id;
        }

        commit('setPlatform', selectedPlatform);
      }

      return { selectedRestaurant, restaurants: userRestaurants };
    });
  },
  getUserRestaurantOrders({ state, commit }) {
    const request = new HttpRequest(SALES.DATE_RANGE, 'GET', true);

    return request.send<GetUserRestaurantOrdersResponse>().then((dateRangeResponse) => {
      const dateRange = { fromDate: '', toDate: '' };

      if (!dateRangeResponse?.fromDate && !dateRangeResponse?.toDate) {
        const now = new Date();
        let month: number | string = now.getMonth() + 1;

        if (month < 10) {
          month = `0${month}`;
        }

        const today = `${now.getFullYear()}-${month}-${now.getDate()}`;

        dateRange.fromDate = today;
        dateRange.toDate = today;
      } else {
        const weekEnd = new Date(dateRangeResponse.toDate);
        const weekBeginning = new Date(
          weekEnd.getFullYear(),
          weekEnd.getMonth(),
          weekEnd.getDate() - 7,
        );

        dateRange.toDate = dateRangeResponse.toDate;
        dateRange.fromDate = weekBeginning.toLocaleDateString('fr-CA'); //YYYY-MM-DD
      }

      commit('setUserOrdersDates', {
        selectedDate: `${dateRange.fromDate} - ${dateRange.toDate}`,
        orderDates: dateRangeResponse.distinctDates,
        dateRange: dateRange,
      });
      state.isDateAlreadySet = true;
    });
  },
  getUserReports({ getters }) {
    let parameters = '';

    if (getters.isAllPlatformsSelected) {
      parameters =
        '?sources=' +
        getters.currentUserRestaurant.restaurants_sources
          .map((source: { source: { id: string } }) => source.source.id)
          .join(',');
    }

    const request = new HttpRequest(PLEEZ_REPORTS + parameters, 'GET', true);

    return request.send<GetUserReportsResponse>();
  },
  getMenuUrl() {
    const request = new HttpRequest('/menus/url', 'GET', true);

    return request.send();
  },
  updatePassword(_, options) {
    const { newPassword } = options;
    const request = new HttpRequest(CHANGE_PASSWORD, 'PUT', true, {
      password: newPassword,
    });

    return request.send();
  },
  getPleezInsights(
    { commit },
    options: GetInsightsOptions = {
      filter: {},
      sort: '',
      order: '',
      offset: 0,
      limit: 0,
    },
  ) {
    const request = new HttpRequest(INSIGHTS.ROOT(options), 'GET', true);

    return request.send<InsightsResponse>().then((response: InsightsResponse) => {
      commit('setPleezInsights', response.data);

      return response;
    });
  },
  getMenuChanges(_, options = { filter: {}, sort: '', order: '', offset: 0, limit: 0 }) {
    const request = new HttpRequest(FUNCTIONS.LIST_USER_FUNCTIONS(options), 'GET', true);

    return request.send();
  },
  updatePleezInsight(_, options) {
    const { id, approved } = options;

    const request = new HttpRequest(`/pleezInsights/${id}`, 'PUT', true, {
      approved,
    });

    return request.send();
  },
  getUserInvoices() {
    const request = new HttpRequest(`/payment/invoices?limit=5000`, 'GET', true);

    return request.send();
  },
  getMenuMetrics() {
    const request = new HttpRequest(METRICS.MENU_QUALITY, 'GET', true);

    return request.send();
  },
  getNewRestaurantMetricsOverview(_, filter) {
    const request = new HttpRequest(METRICS.RESTAURANTS.OVERVIEW(filter), 'GET', true);

    return request.send();
  },
  getSalesPerCategory(_, options) {
    const request = new HttpRequest(
      PRICE_COMPARISON.SALES_ANALYSIS_PER_CATEGORY({ ...options }),
      'GET',
      true,
    );

    return request.send();
  },
  getHomePageDetails() {
    const request = new HttpRequest(HOME, 'GET', true);

    return request.send<HomeResponse>().then((response) => {
      return {
        timeSaver: {
          insights: response.time_saver?.insights,
          menuChanges: response.time_saver?.menu_changes,
        },
        averageTicket: response.average_ticket_7d,
        variationPercentage: response.variation_14d,
        waitingRecommendations: response.waiting_recommendations,
      };
    });
  },
  async getCommonFilters({ commit, state }) {
    const isNotEmpty = Object.values(state.commonFilters).some((arr) => arr.length > 0);

    if (isNotEmpty) return;

    const request = new HttpRequest(RESTAURANTS_PLATFORMS, 'GET', true);

    const response = await request.send<CommonFiltersResponse>();

    if (response) {
      commit('setCommonFilters', response);
    }
  },

  async getCityAndPlatforms({ commit, state }) {
    const isNotEmpty = Object.values(state.cityAndPlatforms).some((arr) => arr.length > 0);

    if (isNotEmpty) return;

    const request = new HttpRequest(CITIES_PLATFORMS, 'GET', true);

    const response = await request.send<CityAndSourcesResponse>();

    const { sources: platforms, cities, defaultCity } = response;

    if (response) {
      commit('setCityAndPlatforms', {
        platforms,
        cities,
        defaultCity,
      });
    }
  },
  getMarketInsights(_, _filter = {}) {
    const request = new HttpRequest(MARKET_TRENDS.GET_ESTIMATED_ORDERS(_filter), 'GET', true);

    return request.send<MarketTrendsResponse>().then((response: MarketTrendsResponse) => {
      const cardPeriods = response.periods?.map((period) => {
        const PERIOD_TYPE_TO_CAMEL: Record<string, MarketInsightsPeriod> = {
          last_week: 'lastWeek',
          last_two_weeks: 'lastTwoWeeks',
          last_month: 'lastFourWeeks',
        };
        const periodType = PERIOD_TYPE_TO_CAMEL[period.period_type];

        if (!period.start_date) {
          console.error('Received an invalid insight period');
          return;
        }

        const startDate = new Date(period.start_date);
        const endDate = new Date(period.end_date);

        return {
          startDate,
          endDate,
          periodType,
          salesVariation: period.sales_variation,
          orderCount: period.orders_count,
        };
      });
      return {
        sampleSize: response.sample_size_restaurants_size,
        cardPeriods: cardPeriods.filter((it) => it) as CardPeriod[],
      };
    });
  },
  async getPriceComparisonCompetitorFilters({ commit }) {
    const request = new HttpRequest(PRICE_COMPARISON.FILTERS, 'GET', true);

    const response = await request.send<PriceComparisonFiltersResponse>();

    const { sources, defaultSource, allRestaurants, defaultRestaurant } = response;

    if (response) {
      commit('setPriceComparisonCompetitorFilters', {
        sources,
        defaultSource,
        allRestaurants,
        defaultRestaurant,
      });
    }
  },
  getPriceComparisonCompetitorMoreFilters(_, filter = {}) {
    const request = new HttpRequest(PRICE_COMPARISON.MORE_FILTERS(filter), 'GET', true);

    return request.send<PriceComparisonMoreFiltersResponse>();
  },
  getSuggestedCompetitors(_, filter = {}) {
    const request = new HttpRequest(PRICE_COMPARISON.SUGGESTED_COMPETITORS(filter), 'GET', true);

    return request.send<SuggestedCompetitorsResponse>();
  },
  getSavedCompetitors(_, filter = {}) {
    const request = new HttpRequest(
      PRICE_COMPARISON.SUGGESTED_COMPETITORS_SAVED(filter),
      'GET',
      true,
    );

    return request.send<SavedCompetitorsResponse>();
  },
  saveSuggestedCompetitors(_, { payload, filter }) {
    const request = new HttpRequest(
      PRICE_COMPARISON.SUGGESTED_COMPETITORS_SAVED(filter),
      'PUT',
      true,
      payload,
    );

    return request.send();
  },
  async getPriceComparisonCategoriesFilter(_, filter = {}) {
    const request = new HttpRequest(PRICE_COMPARISON.CATEGORIES(filter), 'GET', true);

    return await request.send();
  },
  async getPriceComparisonItemsFilter(_, filter = {}) {
    const request = new HttpRequest(PRICE_COMPARISON.ITEMS(filter), 'GET', true);

    return await request.send();
  },
  getPriceMetricsForRegion(_, filter) {
    const request = new HttpRequest(PRICE_COMPARISON.PRICE_METRICS_REGION(filter), 'GET', true);

    return request.send<PriceMetricsResponse>();
  },
  getPriceMetricsForCompetitors(_, filter) {
    const request = new HttpRequest(
      PRICE_COMPARISON.PRICE_METRICS_COMPETITORS(filter),
      'GET',
      true,
    );

    return request.send<PriceMetricsResponse>();
  },
  getPriceComparisonData(_, { filter, sort, offset = 0, limit = 10, search }) {
    const searchParams = new URLSearchParams({
      platform: filter?.platformId?.[0]?.toString() ?? '',
      offset: offset.toString(),
      limit: limit.toString(),
    });

    filter.restaurantIds.forEach((restaurant) =>
      searchParams.append('restaurants', restaurant.toString()),
    );

    filter.categories?.forEach((category) => {
      searchParams.append('category', category.toString());
    });

    filter.idItems?.forEach((item) => {
      searchParams.append('items', item.toString());
    });

    if (sort) {
      searchParams.append('sort', `${sort.key}:${sort.direction}`);
    }
    if (search) {
      searchParams.append('search', `title:${search}`);
    }

    const client = new HttpRequest(PRICE_COMPARISON.ROOT(searchParams), 'GET', true);
    return client.send<PriceComparisonResponse>();
  },
  getInsightTypes() {
    const client = new HttpRequest(INSIGHTS.TYPES, 'GET', true);
    return client.send<InsightType[]>();
  },
  getInsightSettings() {
    const client = new HttpRequest(INSIGHTS.SETTINGS, 'GET', true);
    return client.send<InsightSettings[]>();
  },
  saveInsightSettings(_, insightSettings) {
    const client = new HttpRequest(INSIGHTS.SETTINGS, 'PUT', true, insightSettings);
    return client.send();
  },
  getRestaurantsOverviewByPlatform(_, { startDate, endDate, platforms, restaurants }) {
    let filter = `startDate:${startDate.toISOString().slice(0, 10)};endDate:${endDate
      .toISOString()
      .slice(0, 10)}`;

    if (platforms && platforms.length > 0) {
      filter += `;platformIds:${platforms.join(',')}`;
    }

    if (restaurants && restaurants.length > 0) {
      filter += `;restaurantIds:${restaurants.join(',')}`;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('filter', filter);

    const client = new HttpRequest(
      METRICS.RESTAURANTS.OVERVIEW_BY_PLATFORM(searchParams),
      'GET',
      true,
    );
    return client.send<GetNewRestaurantMetricsOverviewByPlatformResponse[]>();
  },
  getRestaurantsOverviewByRestaurant(_, { startDate, endDate, platforms, restaurants }) {
    let filter = `startDate:${startDate.toISOString().slice(0, 10)};endDate:${endDate
      .toISOString()
      .slice(0, 10)}`;

    if (platforms && platforms.length > 0) {
      filter += `;platformIds:${platforms.join(',')}`;
    }

    if (restaurants && restaurants.length > 0) {
      filter += `;restaurantIds:${restaurants.join(',')}`;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('filter', filter);

    const request = new HttpRequest(
      METRICS.RESTAURANTS.OVERVIEW_BY_RESTAURANT(searchParams),
      'GET',
      true,
    );

    return request.send<GetNewRestaurantMetricsOverviewByRestaurantResponse[]>();
  },
  getPromotions(_, filters) {
    const searchParams = new URLSearchParams();

    if (filters.restaurantIds) {
      filters.restaurantIds.forEach((it) => searchParams.append('restaurantIds[]', it.toString()));
    }
    if (filters.platformIds) {
      filters.platformIds.forEach((it) => searchParams.append('platformIds[]', it.toString()));
    }
    if (filters.promotionTypeIds) {
      filters.promotionTypeIds.forEach((it) =>
        searchParams.append('promotionTypeIds[]', it.toString()),
      );
    }
    if (filters.radius) {
      searchParams.append('radius', filters.radius.toString());
    }
    if (filters.radiusRestaurantId) {
      searchParams.append('radiusRestaurantId', filters.radiusRestaurantId.toString());
    }
    if (filters.restaurantTypes) {
      filters.restaurantTypes.forEach((t) => searchParams.append('restaurantTypes[]', t));
    }

    const formatedFilters = `?${searchParams.toString()}` ?? '';
    const promotionsResponse = new HttpRequest(MARKET.PROMOTIONS + formatedFilters, 'GET', true);
    return promotionsResponse.send();
  },
  getPromotionTypes() {
    const promotionsResponse = new HttpRequest(MARKET.PROMOTIONS_TYPES, 'GET', true);
    return promotionsResponse.send();
  },
  async getPromotionsFilters({ dispatch, state }) {
    await dispatch('getPriceComparisonCompetitorFilters');
    let filters = {
      restaurants: state.priceComparisonCompetitorFilters.allRestaurants,
      platforms: [] as PromotionsFilter['platforms'],
      defaultRestaurant: state.priceComparisonCompetitorFilters.defaultRestaurant,
      defaultSource: state.priceComparisonCompetitorFilters.defaultSource,
    };
    if (isCSMUser()) {
      filters.platforms = await dispatch('getPlatforms');
    } else {
      filters.platforms = state.priceComparisonCompetitorFilters.sources;
    }
    return filters;
  },
  async getPlatforms() {
    const platforms = new HttpRequest(MARKET.PLATFORMS, 'GET', true);
    return platforms.send();
  },
  async getPromotionsTypes() {
    const types = new HttpRequest(MARKET.PROMOTIONS_TYPES, 'GET', true);
    return types.send();
  },
};

export default actions;
