<template>
  <HoveringTooltip
    :message="$t('shared.unlockFeature')"
    :keep-hidden-on-mouse-over="!disabled"
    :follow-mouse="false"
    position="right"
  >
    <div 
      @click="$emit('openChildren', link)"
    >
      <button class="sidebar-link__button">
        <component
          :is="(disabled || hasChildren) ? 'div' : 'router-link'"
          :to="redirectTo"
          @click.native="handleLinkClick"
          :class="[
            'sidebar-link',
            'router-link',
            {
              'router-link-active': isActive,
              'router-link--disabled': disabled,
              'sidebar-link--root-active': isActive,
              'sidebar-link--root-active--no-child': !hasChildren && isActive,
            },
          ]"
        >
          <div class="sidebar-link__label-wrapper">
            <span
              class="sidebar-link__icon"
              :class="{
                'sidebar-link__icon--has-child': hasChildren,
              }"
            >
              <slot />
            </span>
            <p class="sidebar-link__label">{{ label }}</p>
          </div>
        </component>
      </button>
      <!-- Children routes -->
      <ul>
        <component
          :is="disabled ? 'li' : 'router-link'"
          tag="li"
          @click.native="handleLinkClick"
          :class="[
            'sidebar-link',
            'router-link',
            'sidebar-link--child',
            {
              'router-link--disabled': disabled,
              'sidebar-link--child-active': isActiveChildren(ch),
            },
          ]"
          v-if="hasChildren && (currentPathIsChildrenParent || openedChildren)"
          v-for="ch in link.children"
          :key="ch.path"
          :to="`${link.url}/${ch.path}`"
        >
          {{ $t(ch.meta?.title!) }}
        </component>
      </ul>
    </div>
  </HoveringTooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ChildRoute, SideBarRoute } from './Sidebar';
import { HoveringTooltip } from '@pleez-tech/fe-components-library';
import { PropType } from 'vue';
export default defineComponent({
  components: {
    HoveringTooltip,
  },
  emits: {
    close() {
      return true
    },
    openChildren(link: Object) {
      return true
    },
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    link: {
      type: Object as PropType<SideBarRoute>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    openedChildren: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isShowingChildren: false,
    };
  },
  methods: {
    isActiveChildren(ch: ChildRoute) {
      return this.$route.path === `/${this.link.root}/${ch.path}`;
    },
    handleLinkClick() {
      if (!this.hasChildren) {
        this.$emit('close');
      }
    },
  },
  computed: {
    redirectTo() {
      if (this.link.children && this.link.children.length > 0) {
        return `${this.link.url}/${this.link.children[0].path}`;
      } else {
        return this.url;
      }
    },
    isActive() {
      if (this.hasChildren) {
        return this.link.children?.some((ch) => `${this.link.url}/${ch.path}` === this.$route.path);
      } else {
        return this.$route.path == this.url;
      }
    },
    hasChildren() {
      return this.link.children?.length;
    },
    currentPathIsChildrenParent() {
      if (this.hasChildren) {
        // Find the current path in the children array
        const currentChildrenPath = this.link.children?.find(
          (child) => this.$route.path === `${this.link.url}/${child.path}`,
        );
        return currentChildrenPath;
      }

      return false;
    },
  },
});
</script>

<style lang="scss">
.sidebar-link {
  @include brand-font-m;

  display: flex;
  align-items: center;

  margin: var(--spacing-3xs) 0;
  padding: var(--spacing-xxs) 0;

  color: var(--brand-primary);

  text-decoration: none;

  &__button {
    width: 100%;
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
  }
  &__icon {
    width: var(--spacing-s);

    fill: var(--brand-primary);
    stroke: var(--brand-primary);
    stroke-width: 2;
  }
  &__label {
    margin-left: var(--spacing-xxs);
    text-align: start;
  }
  &:hover {
    color: var(--brand-secondary);
    cursor: pointer;

    .sidebar-link__icon {
      stroke: var(--brand-secondary);
      fill: var(--brand-secondary);
      stroke-width: 2;
    }
  }

  &.router-link-active {
    color: var(--brand-secondary);
  }

  &.sidebar-link--child {
    margin-left: var(--spacing-m);
  }

  @include breakpoint-from('smallDesktop') {
    padding: var(--spacing-xxs) var(--spacing-s);

    &.sidebar-link--child-active {
      background-color: transparent;
      color: var(--brand-secondary);
    }

    &.sidebar-link--root-active {
      background-color: var(--brand-grey);
      color: var(--color-black);

      &--no-child {
        color: var(--brand-secondary);
      }
    }

    &.router-link-active {
      position: relative;

      .sidebar-link__icon {
        stroke: var(--brand-secondary);
        fill: var(--brand-secondary);
        stroke-width: 2;

        &--has-child {
          stroke: var(--color-black);
          fill: var(--color-black);
        }
      }
    }
  }
}

.router-link--disabled {
  color: var(--color-grey-text) !important;
  cursor: default !important;
  
  .sidebar-link__icon {
    stroke: var(--color-grey-text) !important;
    fill: var(--color-grey-text) !important;
    stroke-width: 2;
  }
}
</style>
