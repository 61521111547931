<template>
  <div>
    <div
      :class="['modal__overlay', { 'modal__overlay--embedded': isEmbedded }]"
      @click="$emit('close')"
    />

    <div :class="['modal', { 'modal--embedded': isEmbedded }]">
      <div v-if="hasHeader" class="modal__header">
        <span v-if="icon" class="modal__title-icon">
          <component :is="icon" />
        </span>
        <div class="modal__title-wrapper">
          <h2 v-if="title" class="modal__title">{{ title }}</h2>
          <button class="modal__close-icon" @click="$emit('close')">
            <CloseIcon />
          </button>
        </div>

        <p v-if="description" class="modal__description">{{ description }}</p>
      </div>
      <div
        v-if="!isLoading"
        :class="['modal__body', { 'modal__body--overflow-visible': hasOverflowVisible }]"
      >
        <slot />
      </div>
      <div v-else class="modal__loader">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Loader from '../Loader/LoaderComponent.vue';

export default defineComponent({
  components: { Loader },
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    hasOverflowVisible: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.modal {
  --modal-max-width: 86vw;
  --modal-min-width-desktop: 400px;
  --modal-max-width-desktop: 60vw;
  --modal-max-height: 80vh;

  position: fixed;
  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;
  max-width: var(--modal-max-width);
  max-height: var(--modal-max-height);
  height: inherit;
  width: inherit;
  overflow: scroll;

  background-color: var(--grey-background);

  border: 1px solid var(--secondary-grey-stroke);
  border-radius: var(--spacing-xxs);

  transform: translate(-50%, -50%);

  z-index: 13;

  &--embedded {
    position: absolute;

    z-index: 11;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: var(--spacing-xs);

    border-bottom: 1px solid var(--secondary-grey-stroke);
  }
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }
  &__title {
    @include brand-font-m;

    margin-right: var(--spacing-s);

    color: var(--brand-primary);

    font-weight: 500;
  }
  &__description {
    @include brand-font-xm;

    margin-top: var(--spacing-xxs);
  }
  &__title-icon {
    margin-right: var(--spacing-xxs);

    stroke: var(--brand-primary);
  }
  &__close-icon {
    margin-left: auto;
    cursor: pointer;

    stroke: var(--brand-secondary);
  }
  &__body {
    @include brand-font-m;

    display: flex;
    flex-direction: column;
    flex: 1;
    //justify-content: safe center is not yet supported in most browsers;
    margin: auto 0;
    padding: var(--spacing-xs);

    // overflow: auto;

    &--overflow-visible {
      overflow: visible;
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.35);

    z-index: 13;

    &--embedded {
      position: absolute;

      background-color: rgba(0, 0, 0, 0);

      backdrop-filter: blur(2px);

      z-index: 11;
    }
  }
  &__loader {
    position: relative;

    height: var(--spacing-xxl);

    z-index: 11;
  }

  @include breakpoint-from('tablet') {
    min-width: var(--modal-min-width-desktop);
    max-width: var(--modal-max-width-desktop);
  }

  @include breakpoint-from('desktop') {
    --modal-max-width-desktop: 80vw;
  }
}
</style>
