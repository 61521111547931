<template>
  <div class="loader">
    <div class="loader__circle">
      <div
        :class="[isMini ? 'loader__circle-icon--mini' : '', 'loader__circle-icon']"
        :aria-label="$t('shared.inputs.loading')"
      />
      <PleezIcon v-if="!isMini" class="loader__icon" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    return { currentLanguage: store.getters.currentLanguage };
  },
  props: {
    isMini: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  display: inline-flex;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    fill: var(--brand-secondary);
    stroke: var(--brand-secondary);
  }
  &__circle {
    display: inline-block;

    stroke: var(--brand-secondary);
  }
  &__circle-icon {
    width: 64px;
    height: 64px;

    font-size: 4px;

    border-top: 0.9em solid rgba(var(--brand-primary-rgb), 0.2);
    border-right: 0.9em solid rgba(var(--brand-primary-rgb), 0.2);
    border-bottom: 0.9em solid rgba(var(--brand-primary-rgb), 0.2);
    border-left: 0.9em solid rgba(var(--brand-secondary-rgb), 0.8);

    animation: rotate 1.1s infinite linear;
    transition: opacity 0.1s;

    border-radius: 50%;

    &--mini {
      width: 16px;
      height: 16px;

      font-size: 5px;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate3d(0, 0, 1, 360deg);
    }
  }
}
</style>
