<template>
  <div class="filters" v-if="!hasCriticalErrors">
    <Modal
      v-if="shouldShowConfirmationModal"
      :title="$t('filters.platforms.changePlatformModal.title')"
      @close="cancelChangePlatform"
    >
      <div class="filters__confirmation-modal">
        <p-button
          class="filters__modal-button filters__modal-button--cancel"
          label="Cancel"
          @click="cancelChangePlatform"
        >
          {{ $t('shared.cancel') }}
        </p-button>
        <p-button
          class="filters__modal-button filters__modal-button--submit"
          label="Confirm"
          @click="changePlatform"
        >
          {{ $t('filters.platforms.changePlatformModal.confirm') }}
        </p-button>
      </div>
    </Modal>

    <div class="filters__group">
      <div v-if="shouldShowPlatforms" class="filters__platforms">
        <PlatformFilter :showAllPlatforms="filters.allPlatforms" @click="openPlatformModal" />
      </div>

      <div
        :class="[
          { 'filters__dropdowns--full-width': isFullWidth },
          { 'filters__dropdowns--is-sub-header': isStandAlone },
          'filters__dropdowns',
        ]"
      >
        <!-- Space for custom filters -->
        <div v-if="filters.custom" class="filters__custom">
          <slot />

          <button v-if="filters.shouldShowHint" class="filters__hint" @click="handleHintOnClick">
            <span class="filters__hint-label"> {{ $t('filters.hint') }}</span>
            <span class="filters__hint-icon">
              <InfoIcon />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import PlatformFilter from '@/components/Filters/PlatformFilter.vue';
import Modal from '@/components/Modal/ModalComponent.vue';

import { Filters } from './Filters';

export default defineComponent({
  components: {
    PlatformFilter,
    Modal,
  },
  props: {
    filters: {
      type: Object as PropType<Filters>,
      required: true,
    },
    isStandAlone: {
      type: Boolean,
      default: false,
    },
    noLabels: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newPlatform: 0,
      shouldShowConfirmationModal: false,
      hasCriticalErrors: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    shouldShowPlatforms() {
      return Boolean(this.filters.platform || this.filters.allPlatforms);
    },
    isFullWidth() {
      return !this.shouldShowPlatforms;
    },
  },
  methods: {
    openPlatformModal(index: string | number) {
      const platformIndex = Number(index);
      const currentSelectedPlatform = this.$store.state.subHeaderSelectedPlatform;

      if (currentSelectedPlatform !== platformIndex) {
        this.shouldShowConfirmationModal = true;
        this.newPlatform = platformIndex;
      }
    },
    cancelChangePlatform() {
      this.shouldShowConfirmationModal = false;
    },
    changePlatform() {
      this.shouldShowConfirmationModal = false;
      this.$store
        .dispatch('changeRestaurantPlatform', { newPlatform: this.newPlatform })
        ?.catch(() => this.$toast.error(this.$t('errors.changePlatform')));
    },
    handleHintOnClick() {
      this.$emit('showModal');
    },
  },
  watch: {
    '$store.getters.criticalErrors'(errors) {
      if (errors.filters) {
        this.hasCriticalErrors = true;
      }
    },
  },
});
</script>

<styles scoped lang="scss">
.filters {
  --min-height: 80px;

  width: 100%;
  min-height: var(--min-height); //Reduce CLS

  margin-top: var(--spacing-xxs);

  &__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    min-height: var(--min-height);
  }
  &__platforms {
    width: 100%;
    margin-bottom: var(--spacing-m);
    padding-bottom: var(--spacing-m);

    border-bottom: 1px solid var(--secondary-grey-stroke);
  }
  &__dropdowns {
    width: 100%;
  }
  &__confirmation-modal {
    display: flex;
    justify-content: center;
  }
  &__modal-button {
    width: 136px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 24px;

    &--cancel {
      margin-right: 1em;
    }
  }
  &__modal-button--submit {
    color: #ffffff;
    background-color: var(--brand-tertiary);
  }
  &__modal-title {
    display: flex;
  }
  &__modal-icon {
    margin-right: 8px;
  }
  &__hint {
    width: 100%;
  }
  &__hint-label {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: var(--input-height);

    padding: 4px;

    border: 1px solid var(--input-border);
    border-radius: 4px;
    background-color: var(--grey-background);

    box-sizing: border-box;
  }
  &__hint-icon {
    display: none;
  }

  @include breakpoint-from('smallDesktop') {
    &__group {
      flex-direction: row;
      justify-content: space-between;
    }
    &__platforms {
      min-height: 86px;
    }
    &__dropdowns {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      max-width: 1000px;

      &--full-width {
        max-width: unset;

        justify-content: flex-start;
      }
      &--is-sub-header {
        justify-content: flex-end;
      }
    }
    &__custom {
      display: flex;
      width: 100%;
      max-width: 1000px;
    }
    &__hint {
      width: fit-content;
    }
    &__hint-label {
      display: none;
    }
    &__hint-icon {
      display: block;
    }
  }

  @include breakpoint-from('smallDesktop') {
    &__dropdowns {
      flex-direction: row;
    }

    &__group {
      align-items: center;
    }
  }
}
</styles>
