import HttpRequest from "@/http/httpClass";
import { defineStore } from "pinia";
import { ref } from "vue";
import type { FeatureFlag } from "./store";

export const useFeatureFlagsStore = defineStore('featureFlags', () => {
  const featureFlags = ref<FeatureFlag[]>([])

  const fetchFeatureFlags = async () => {
    if (!featureFlags.value.length) {
      const request = new HttpRequest(
        '/v2/feature-flag',
        'GET',
        true
      )
  
      featureFlags.value = await request.send()
    }
  }
  

  return { featureFlags, fetchFeatureFlags }
})