<template>
  <div v-if="page" class="bread-crumb">
    <div class="bread-crumb__headers" v-if="pageMeta.breadcrumbs">
      <h1 class="bread-crumb__header">
        {{ page }}
      </h1>
      <h2 class="bread-crumb__subPage" v-if="pageMeta.breadcrumbs.subPage.length === 1">
        {{ subPage }}
      </h2>
      <div class="bread-crumb__subPages" v-else>
        <div
          v-for="(subPageItem, index) in pageMeta.breadcrumbs.subPage"
          :key="`${subPageItem} ${index}`"
        >
          <router-link
            v-if="
              (!isSuperUserLocked(pageMeta) || isSuperUser) &&
              pageMeta.breadcrumbs.subPagesIdentifier
            "
            class="bread-crumb__subPage--link"
            :to="`/${pageMeta.root}/${pageMeta.breadcrumbs.subPagesIdentifier[index]}`"
          >
            <h2 class="bread-crumb__subPage bread-crumb__subPage--tab">
              <span
                :class="{
                  'bread-crumb__active-tab': isSubPageActive(index),
                }"
              >
                {{ $t(subPageItem) }}
              </span>
            </h2>
          </router-link>
        </div>
      </div>
    </div>
    <div class="bread-crumb__date-picker">
      <Datepicker
        v-if="shouldShowDatePicker"
        :initialDate="datePickerInitialDate"
        :highlightDates="highlightDates"
        is-range-calendar
        is-sales-calendar
        @handleRangeDateSelected="handleDateRangeChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Datepicker from '@/components/Inputs/DatePicker/DatePickerComponent.vue';
import { isSuperUserLocked, isSuperUser } from '@/utils/superUser';
import { RouteMeta, SupportedLanguages } from '@/routes/routesInterface';
import { globals } from '@/main';

export default defineComponent({
  components: {
    Datepicker,
  },
  methods: {
    isSuperUserLocked,
    isSubPageActive(index: number) {
      const breadCrumbs = this.routeBreadcrumbs;

      if (!breadCrumbs) return false;

      return index === breadCrumbs.activeSubPageIndex;
    },
    handleDateRangeChange(selectedDate: string) {
      const [fromDate, toDate] = selectedDate.split(' - ');
      this.$store.commit('setUserOrdersDates', {
        selectedDate: selectedDate,
        dateRange: { fromDate, toDate },
      });
    },
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.selectedLanguage as SupportedLanguages;
    },
    routeBreadcrumbs() {
      const routeMeta = this.$route.meta as RouteMeta;

      if (!routeMeta) return null;
      if (!routeMeta.breadcrumbs) return null;

      return routeMeta.breadcrumbs;
    },
    isSuperUser() {
      return isSuperUser();
    },
    shouldShowDatePicker() {
      return Boolean(this.$route.meta?.hasDatePicker);
    },
    page() {
      const breadCrumbs = this.routeBreadcrumbs;
      if (!breadCrumbs) return '';
      return globals.$t(breadCrumbs.page);
    },
    subPage() {
      const breadCrumbs = this.routeBreadcrumbs;
      if (!breadCrumbs) return '';
      return globals.$t(breadCrumbs.subPage[0]) || '-';
    },
    pageMeta() {
      return this.$route.meta as RouteMeta;
    },
    datePickerInitialDate() {
      return (
        this.$store.getters.selectedDateRange.selectedDate ??
        [new Date(), new Date()].map((it) => it.toISOString().slice(0, 10)).join(' - ')
      );
    },
    highlightDates() {
      return this.$store.state.userOrderDates;
    },
  },
});
</script>

<style lang="scss">
.bread-crumb {
  --date-picker-max-width: 340px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--spacing-xs);

  position: sticky;
  top: 0;
  padding: var(--spacing-xs) 0 var(--spacing-s) 0;

  color: var(--brand-primary);
  background-color: var(--color-white);

  z-index: 12; //So it can be higher than apex-charts

  &__active-tab {
    color: var(--brand-secondary);
    border-bottom: 1px solid var(--brand-secondary);
  }

  &__headers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    margin-right: var(--spacing-xs);

    gap: var(--spacing-xs);
  }

  &__header {
    font-size: 1.5rem;
  }
  &__subPages {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    gap: var(--spacing-3xs);
  }
  &__subPage {
    font-size: 1.2rem;

    font-weight: 400;

    &:before {
      padding-right: 8px;
      margin-left: 12px;

      content: '|';
    }

    &--link {
      text-decoration: none;
      color: var(--color-black);
    }

    &--tab {
      cursor: pointer;
    }
  }
  &__date-picker {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  @include breakpoint-from('tablet') {
    &__date-picker {
      justify-content: flex-end;

      max-width: var(--date-picker-max-width);
    }
  }
}
</style>
