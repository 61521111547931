<template>
  <div v-if="isLoading" class="platform-icon__loader">
    <LoaderComponent isMini />
  </div>
  <div
    v-else-if="platform === 'UberEats'"
    :class="['platform-icon', 'platform-icon--uber', { 'platform-icon--big': isBig }]"
  >
    <UberEatsIcon aria-label="Uber eats" />
  </div>
  <div
    v-else-if="platform === 'Bolt'"
    :class="['platform-icon', 'platform-icon--bolt', { 'platform-icon--big': isBig }]"
  >
    <BoltIcon aria-label="Bolt food" />
  </div>
  <div
    v-else-if="platform === 'Glovo'"
    :class="['platform-icon', 'platform-icon--glovo', { 'platform-icon--big': isBig }]"
  >
    <GlovoIcon aria-label="Glovo" />
  </div>
  <div
    v-else-if="platform === 'JustEat'"
    :class="['platform-icon', 'platform-icon--justEat', { 'platform-icon--big': isBig }]"
  >
    <JustEatIcon aria-label="Just eat" />
  </div>
  <div
    v-else-if="platform === 'Deliveroo'"
    :class="['platform-icon', 'platform-icon--deliveroo', { 'platform-icon--big': isBig }]"
  >
    <DeliverooIcon aria-label="Deliveroo" />
  </div>
  <div
    v-else-if="platform === 'User'"
    :class="['platform-icon', 'platform-icon--user', { 'platform-icon--big': isBig }]"
  >
    <YouIcon aria-label="You" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoaderComponent from '@/components/Loader/LoaderComponent.vue';

export default defineComponent({
  name: 'PlatformIcon',
  components: {
    LoaderComponent,
  },
  props: {
    platform: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    platforms() {
      return this.$store.getters.platforms;
    },
  },
});
</script>

<style lang="scss" scoped>
.platform-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: var(--spacing-l);
  height: var(--spacing-l);

  border: 1px solid $grey-lines;
  border-radius: 10px;

  &--uber {
    background-color: var(--color-black);
  }
  &--bolt {
    background-color: #f4f4f4;
  }
  &--glovo {
    background-color: #ffcc1b;
  }
  &--justEat {
    background-color: #ff8000;
  }
  &--deliveroo {
    background-color: #00ccbc;
  }
  &--user {
    color: #ffffff;
    background-color: $brand-secondary;

    font-weight: bold;
  }
  &--big {
    width: var(--platform-icon-size-big);
    height: var(--platform-icon-size-big);

    & > svg {
      transform: scale(1.2);
    }
  }
  &__loader {
    position: relative;

    width: var(--spacing-l);
    height: var(--spacing-l);

    border: 1px solid $grey-lines;
    border-radius: 10px;

    background-color: $grey-background;
  }
}
</style>
